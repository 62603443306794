import useAuth from "../../hooks/useAuth";
import api from "../../services/api";
import "./report.css"; // Seu CSS para estilização extra
import html2pdf from "html2pdf.js"; // Certifique-se de importar o html2pdf

// Função para gerar o conteúdo HTML
const printContent = async (data) => {
  const content = document.createElement('div');
  const { personName, initDateRef, endDateRef, resData } = data

  const initDate = new Date(initDateRef.current.value);
  const endDate = new Date(endDateRef.current.value);
  const diffInMs = endDate - initDate;
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  content.innerHTML = `
    <div class="container">
      <h1>Relatório por agentes</h1>
      <p>Este relatório apresenta a análise de desempenho de cada agente de atendimento no período de <strong>${diffInDays} dias</strong>. O objetivo é avaliar a eficácia de cada agente e identificar áreas que necessitam de melhorias.</p>

      <h2>Desempenho Geral</h2>
      
      <h3>Total de Atendimentos por Agente</h3>
      <table>
        <thead>
          <tr>
            <th>AGENTE DE ATENDIMENTO</th>
            <th>TOTAL DE MENSAGENS</th>
          </tr>
        </thead>
        <tbody>
        ${personName.map((q, index) => `
            <tr>
              <td>${q.name}</td>
              <td>${resData[index].totalMessages}</td>
            </tr>
          `)
    }
          <!-- Adicionar mais canais conforme necessário -->
        </tbody>
      </table>

      <h3>Média de Mensagens por Atendimento</h3>
      <table>
        <thead>
          <tr>
            <th>AGENTE DE ATENDIMENTO</th>
            <th>MÉDIA DE MENSAGEM POR ATENDIMENTO</th>
          </tr>
        </thead>
        <tbody>
          ${personName.map((u, index) => `
              <tr>
                <td>${u.name}</td>
                <td>${parseInt(resData[index].averageMessagesPerTicket)}</td>
              </tr>
            `)
    }
          <!-- Adicionar mais agentes conforme necessário -->
        </tbody>
      </table>

      <h2>Análise Detalhada por Agente</h2>
      ${personName.map((q, index) => `
        <h3>${q.name}</h3>
        <p>Total de Atendimentos: <strong>${resData[index].totalAtendimentos}</strong></p>
        <p>Total de tickets abertos: <strong>${resData[index].totalTicketsAbertos}</strong></p>
        <p>Total de tickets pausados: <strong>${resData[index].totalTicketsPausados}</strong></p>
        <p>Total de tickets resolvidos: <strong>${resData[index].totalTicketsResolvidos}</strong></p>
        <p>Tempo Médio de Resposta: <strong>${resData[index].averageResponseTime > 60 ? parseInt(resData[index].averageResponseTime / 60) + " horas" : parseInt(resData[index].averageResponseTime) + " minutos"}</strong></p>
        <p>Tempo Médio da Primeira Resposta: <strong>${resData[index].averageFirstResponseTime > 60 ? parseInt(resData[index].averageFirstResponseTime / 60) + " horas" : parseInt(resData[index].averageFirstResponseTime) + " minutos"}</strong></p>
        <p>Tempo Médio de resolução total do problema: <strong>${resData[index].averageResolutionTime > 60 ? parseInt(resData[index].averageResolutionTime / 60) + " horas": parseInt(resData[index].averageResolutionTime) + " minutos"}</strong></p>
      `)
    }
    </div>
  `;

  return content;
};

// Função para gerar o PDF usando html2pdf
export const generatePDF = async (data) => {
  const { user, initDateRef, endDateRef, personName } = data

  try {
    const requests = personName.map(u =>
      api.get(`/users/${u.id}/general-info`, {
        params: {
          initDateRef: initDateRef.current.value,
          endDateRef: endDateRef.current.value
        }
      })
    );

    const responses = await Promise.all(requests)
    const resData = await Promise.all(responses.map(res => res.data))

    const content = await printContent({ ...data, resData }); // Seu conteúdo HTML

    const options = {
      margin: 1,
      filename: 'Relatório_por_Agentes.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'portrait'
      }
    };

    // Adiciona cabeçalho e rodapé personalizados
    html2pdf().from(content).set(options).toPdf().get('pdf').then(function (pdf) {
      const totalPages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Carregar a imagem da logo (exemplo: base64 ou caminho da imagem)
      const logoImg = 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/1280px-Coca-Cola_logo.svg.png'; // Exemplo de uma imagem base64 da logo

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);

        // Cabeçalho - Logo e Nome da Empresa
        pdf.setFontSize(12);
        pdf.addImage(logoImg, 'PNG', 1, pageWidth - 7.5, 1, 0.5); // Adiciona a logo no canto esquerdo
        pdf.text('EMPRESA TOPZERA BROKK', pageWidth - 2.5, 1); // Nome da empresa ao lado da logo

        // Rodapé - Nome do Emitente e Data
        const currentDate = new Date().toLocaleDateString('pt-BR');
        pdf.setFontSize(10);
        pdf.text(`Emitido por: ${user.name}`, pageWidth - 7.5, pageHeight - 1);
        pdf.text(`Data de emissão: ${currentDate}`, pageWidth - 2.5, pageHeight - 1);
      }
    }).save();
  } catch (error) {
    console.error('Erro ao buscar dados dos usuários:\n', error);
  }


};

export default printContent;

import useAuth from "../../hooks/useAuth";
import api from "../../services/api";
import "./report.css"; // Seu CSS para estilização extra
import html2pdf from "html2pdf.js"; // Certifique-se de importar o html2pdf

// Função para gerar o conteúdo HTML
const printContent = async (data) => {
  const content = document.createElement('div');
  const { personName, resData, ticketsResponses, initDateRef, endDateRef } = data

  console.log(resData)

  const initDate = new Date(initDateRef.current.value);
  const endDate = new Date(endDateRef.current.value);
  // Calculando a diferença em milissegundos
  const diffInMs = endDate - initDate;
  // Convertendo a diferença de milissegundos para dias
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  content.innerHTML = `
    <div class="container">
      <h1>Relatório por canal de atendimento</h1>
      <p>Neste relatório, apresentamos a análise de desempenho dos canais de atendimento no período de <strong>${diffInDays} dias</strong>. O objetivo é identificar tendências, pontos fortes e áreas que necessitam de melhorias.</p>

      <h2>Desempenho Geral</h2>
      
      <h3>Total de Atendimentos</h3>
      <table>
        <thead>
          <tr>
            <th>CANAL DE ATENDIMENTO</th>
            <th>TOTAL DE ATENDIMENTOS</th>
          </tr>
        </thead>
        <tbody>
        ${personName.map((q, i) => `
            <tr>
              <td>${q.name}</td>
              <td>${resData[i].totalAtendimentos}</td>
            </tr>
          `)
    }
          <!-- Adicionar mais canais conforme necessário -->
        </tbody>
      </table>

      <!--
      <h3>Satisfação do Cliente</h3>
      <table>
        <thead>
          <tr>
            <th>CANAL DE ATENDIMENTO</th>
            <th>ÍNDICE DE SATISFAÇÃO (%)</th>
          </tr>
        </thead>
        <tbody>
          ${personName.map(u => `
              <tr>
                <td>${u.name}</td>
                <td>[Números]</td>
              </tr>
            `)
    }
           Adicionar mais agentes conforme necessário
        </tbody>
      </table>
       -->

      <h2>Análise Detalhada por Canal</h2>
      ${personName.map((q, i) =>  `
        <h3>${q.name}</h3>
        <p>Total de Atendimentos: <strong>${resData[i].totalAtendimentos}</strong></p>
        <!--<p>Índice de Satisfação</p>-->
        <p>Tempo Médio de Resposta: <strong>${resData[i].averageResponseTime > 0 ? parseInt(resData[i].averageResponseTime / 60) + " horas" : parseInt(resData[i].averageResponseTime) + " minutos"}</strong></p>
        <p>Tempo médio para a primeira resposta: <strong>${resData[i].averageFirstResponseTime > 0 ? parseInt(resData[i].averageFirstResponseTime / 60) + " horas" : parseInt(resData[i].averageFirstResponseTime) + " minutos"}</strong></p>
        <p>Tempo para a resolução total do problema: <strong>${resData[i].averageResolutionTime > 0 ? parseInt(resData[i].averageResolutionTime / 60) + " horas" : parseInt(resData[i].averageResolutionTime) + " minutos"}</strong></p>
      `
    )
    }
    </div>
  `;

  return content;
};

// Função para gerar o PDF usando html2pdf
export const generatePDF = async (data) => {
  const { user, initDateRef, endDateRef, personName } = data

  try {
    const requests = personName.map(u =>
      api.get(`/queues/${u.id}/general-info`, {
        params: {
          initDateRef: initDateRef.current.value,
          endDateRef: endDateRef.current.value
        }
      })
    );
    const responses = await Promise.all(requests)
    const resData = await Promise.all(responses.map(res => res.data))

    const ticketRequests = personName.map(queue => {
      const tickets = queue.users.flatMap(user => user.tickets || []);
      const requests = tickets.map(ticket =>
        api.get(`/tickets/${ticket.id}/general-info`, {
          params: {
            initDateRef: initDateRef.current.value,
            endDateRef: endDateRef.current.value
          }
        })
      );
      return Promise.all(requests).then(responses => ({
        queue: queue.name,
        data: responses.map(res => res.data)
      }));
    });

    const ticketsResponses = await Promise.all(ticketRequests);

    const content = await printContent({ ...data, resData, ticketsResponses }); // Seu conteúdo HTML

    const options = {
      margin: 1,
      filename: 'Relatório_por_Canal_de_Atendimento.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'portrait'
      }
    };

    // Adiciona cabeçalho e rodapé personalizados
    html2pdf().from(content).set(options).toPdf().get('pdf').then(function (pdf) {
      const totalPages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Carregar a imagem da logo (exemplo: base64 ou caminho da imagem)
      const logoImg = 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/1280px-Coca-Cola_logo.svg.png'; // Exemplo de uma imagem base64 da logo

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);

        // Cabeçalho - Logo e Nome da Empresa
        pdf.setFontSize(12);
        pdf.addImage(logoImg, 'PNG', 1, pageWidth - 7.5, 1, 0.5); // Adiciona a logo no canto esquerdo
        pdf.text('EMPRESA TOPZERA BROKK', pageWidth - 2.5, 1); // Nome da empresa ao lado da logo

        // Rodapé - Nome do Emitente e Data
        const currentDate = new Date().toLocaleDateString('pt-BR');
        pdf.setFontSize(10);
        pdf.text(`Emitido por: ${user.name}`, pageWidth - 7.5, pageHeight - 1);
        pdf.text(`Data de emissão: ${currentDate}`, pageWidth - 2.5, pageHeight - 1);
      }
    }).save();
  } catch (error) {
    console.error('Erro ao buscar dados das áreas:\n', error);
  }
};

export default printContent;

import useAuth from "../../hooks/useAuth";
import api from "../../services/api";
import "./report.css"; // Seu CSS para estilização extra
import html2pdf from "html2pdf.js"; // Certifique-se de importar o html2pdf

// Função para gerar o conteúdo HTML
const printContent = async (data) => {
  const content = document.createElement('div');
  const { personName, initDateRef, endDateRef, qResData, tResData, queues } = data

  console.log(tResData)

  const initDate = new Date(initDateRef.current.value);
  const endDate = new Date(endDateRef.current.value);
  // Calculando a diferença em milissegundos
  const diffInMs = endDate - initDate;
  // Convertendo a diferença de milissegundos para dias
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  content.innerHTML = `
    <div class="container">
      <h1>Relatório por Atendimentos</h1>
      <p>Este relatório apresenta a análise de cada atendimento realizado no período de <strong>${diffInDays} dias</strong>. O objetivo é avaliar a qualidade e a eficácia de cada atendimento e identificar áreas que necessitam de melhorias.</p>

      <h2>Desempenho Geral</h2>
      
      <h3>Total de Atendimentos</h3>
      <table>
        <thead>
          <tr>
            <th>ATENDIMENTO</th>
            <th>AGENTE RESPONSÁVEL</th>
            <th>CANAL</th>
            <th>DATA E HORA</th>
            <th>Tempo de Atendimento (minutos)</th>
          </tr>
        </thead>
        <tbody>
        ${personName.map((q, i) => {
    const initDate = new Date(q.createdAt).toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/Sao_Paulo"
    });

    const create = new Date(q.createdAt);
    const update = new Date(q.updatedAt);
    const diffInMs = update - create;
    const diffInMin = diffInMs / (1000 * 60);

    return `
                  <tr>
                    <td>${q.id + " - " + q.contact.name}</td>
                    <td>${q.user.name}</td>
                    <td>${q.queue.name}</td>
                    <td>${initDate}</td>
                    <td>${parseInt(diffInMin)}</td>
                  </tr>
                `})
    }
          <!-- Adicionar mais canais conforme necessário -->
        </tbody>
      </table>

      <h3>Média de Mensagens por Atendimento</h3>
      <table>
        <thead>
          <tr>
            <th>CANAL DE ATENDIMENTO</th>
            <th>MÉDIA DE MENSAGEM POR ATENDIMENTO</th>
          </tr>
        </thead>
        <tbody>
          ${queues.map((q, i) => `
              <tr>
                <td>${q.id + " - " + q.name}</td>
                <td>${parseInt(qResData[i].averageMessagesPerTicket)}</td>
              </tr>
            `)
    }
          <!-- Adicionar mais agentes conforme necessário -->
        </tbody>
      </table>

      <h2>Análise Detalhada por Atendimento</h2>
      ${personName.map((q, i) => {
        const create = new Date(q.createdAt);
        const update = new Date(q.updatedAt);
        const diffInMs = update - create;
        const diffInMin = diffInMs / (1000 * 60);
        
        return `
            <h3>${q.id + " - " + q.contact.name}</h3>
            <p>Agente Responsável: <strong>${q.user.name}</strong></p>
            <p>Canal de Atendimento: <strong>${q.queue.name}</strong></p>
            <p>Data e Hora: <strong>${new Date(q.createdAt).toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "America/Sao_Paulo"
          })
            }</strong></p>
            <p>Tempo para a Primeira Resposta: <strong>${tResData[i].firstResponseTime > 60 ? parseInt(tResData[i].firstResponseTime / 60) + " horas" : parseInt(tResData[i].firstResponseTime) + " minutos"}</strong></p>
            <p>Tempo Médio de Resposta: <strong>${tResData[i].avgResponseTime > 60 ? parseInt(tResData[i].avgResponseTime / 60) + " horas" : parseInt(tResData[i].avgResponseTime) + " minutos"}</strong></p>
            <p>Tempo para a resolução total do problema: <strong>${diffInMin > 60 ? parseInt(diffInMin / 60) + " horas" : parseInt(diffInMin) + " minutos"}</strong></p>
    `})
    }
    </div>
  `;

  return content;
};

// Função para gerar o PDF usando html2pdf
export const generatePDF = async (data) => {
  const { user, personName, initDateRef, endDateRef } = data

  try {
    const queues = personName.map(ticket => ticket.queue) // Extrai o objeto 'queue' de cada ticket
      .filter((queue, index, self) =>
        index === self.findIndex(q => q.id === queue.id) // Filtra para manter apenas filas únicas pelo 'id'
      );

    const qRequests = queues.map(u =>
      api.get(`/queues/${u.id}/general-info`, {
        params: {
          initDateRef: initDateRef.current.value,
          endDateRef: endDateRef.current.value
        }
      })
    );
    const qResponses = await Promise.all(qRequests)
    const qResData = await Promise.all(qResponses.map(res => res.data))

    const tRequests = personName.map(u =>
      api.get(`/tickets/${u.id}/general-info`, {
        params: {
          initDateRef: initDateRef.current.value,
          endDateRef: endDateRef.current.value
        }
      })
    );
    const tResponses = await Promise.all(tRequests)
    const tResData = await Promise.all(tResponses.map(res => res.data))

    const content = await printContent({ ...data, qResData, tResData, queues }); // Seu conteúdo HTML

    const options = {
      margin: [1.5, 0],
      filename: 'Relatório_por_Atendimentos.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'portrait'
      }
    };

    // Adiciona cabeçalho e rodapé personalizados
    html2pdf().from(content).set(options).toPdf().get('pdf').then(function (pdf) {
      const totalPages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Carregar a imagem da logo (exemplo: base64 ou caminho da imagem)
      const logoImg = 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/1280px-Coca-Cola_logo.svg.png'; // Exemplo de uma imagem base64 da logo

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);

        // Cabeçalho - Logo e Nome da Empresa
        pdf.setFontSize(12);
        pdf.addImage(logoImg, 'PNG', 1, pageWidth - 7.5, 1, 0); // Adiciona a logo no canto esquerdo
        pdf.text('EMPRESA TOPZERA BROKK', pageWidth - 2.5, 0.9); // Nome da empresa ao lado da logo

        // Rodapé - Nome do Emitente e Data
        const currentDate = new Date().toLocaleDateString('pt-BR');
        pdf.setFontSize(10);
        pdf.text(`Emitido por: ${user.name}`, pageWidth - 7.5, pageHeight - 0.25);
        pdf.text(`Data de emissão: ${currentDate}`, pageWidth - 2.5, pageHeight - 0.25);
      }
    }).save();
  } catch (error) {
    console.error('Erro ao buscar dados das Áreas:', error);
  }

};

export default printContent;

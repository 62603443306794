import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AuthProvider } from "../context/Auth/AuthContext";
import { SocketProvider } from "../context/SocketProvider";
import { TicketsProvider } from "../context/Tickets/TicketsContext";
import LoggedInLayout from "../layout";
import Contacts from "../pages/Contacts/";
import Dashboard from "../pages/Dashboard/";
import Login from "../pages/Login/";
import Queues from "../pages/Queues/";
import QuickAnswers from "../pages/QuickAnswers/";
import Reports from "../pages/Reports/";
import Settings from "../pages/Settings/";
import Tickets from "../pages/Tickets/";
import Users from "../pages/Users";
import Route from "./Route";

const Routes = () => {
  return (
    <BrowserRouter>
      <SocketProvider>
        <AuthProvider>
          <TicketsProvider>
            <Switch>
              <Route exact path="/login" component={Login} />
              <LoggedInLayout>
                <Route exact path="/" component={Dashboard} isPrivate />
                <Route
                  exact
                  path="/tickets/:ticketId?"
                  component={Tickets}
                  isPrivate
                />
                <Route exact path="/contacts" component={Contacts} isPrivate />
                <Route exact path="/users" component={Users} isPrivate />
                <Route
                  exact
                  path="/quickAnswers"
                  component={QuickAnswers}
                  isPrivate
                />
                <Route exact path="/Settings" component={Settings} isPrivate />
                <Route exact path="/Queues" component={Queues} isPrivate />
                <Route exact path="/relatorios" component={Reports} isPrivate />
              </LoggedInLayout>
            </Switch>
            <ToastContainer autoClose={3000} />
          </TicketsProvider>
        </AuthProvider>
      </SocketProvider>
    </BrowserRouter>
  );
};

export default Routes;

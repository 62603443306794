import api from "../../services/api";
import "./report.css"; // Seu CSS para estilização extra
import html2pdf from "html2pdf.js"; // Certifique-se de importar o html2pdf

// Função para gerar o conteúdo HTML
const printContent = async (data) => {
  const content = document.createElement('div');
  const { personName, initDateRef, endDateRef, qResData, ticketsResponses, queues } = data

  const initDate = new Date(initDateRef.current.value);
  const endDate = new Date(endDateRef.current.value);
  // Calculando a diferença em milissegundos
  const diffInMs = endDate - initDate;
  // Convertendo a diferença de milissegundos para dias
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  content.innerHTML = `
    <div class="container">
      <h1>Relatório total de mensagens por atendimento</h1>
      <p>Este relatório apresenta a análise do total de mensagens trocadas por atendimento no período de <strong>${diffInDays} dias</strong>. O objetivo é entender a carga de comunicação necessária por atendimento e identificar possíveis áreas de melhoria na eficiência do atendimento.</p>

      <h2>Desempenho Geral</h2>
      
      <h3>Total de Mensagens por Atendimento</h3>
      <table>
        <thead>
          <tr>
            <th>CANAL DE ATENDIMENTO</th>
            <th>TOTAL DE MENSAGENS</th>
          </tr>
        </thead>
        <tbody>
        ${queues.map((q, i) => `
            <tr>
              <td>${q.name}</td>
              <td>${qResData[i].totalMessages}</td>
            </tr>
          `)
    }
          <!-- Adicionar mais canais conforme necessário -->
        </tbody>
      </table>

      <h3>Média de Mensagens por Atendimento</h3>
      <table>
        <thead>
          <tr>
            <th>CANAL DE ATENDIMENTO</th>
            <th>MÉDIA DE MENSAGEM</th>
          </tr>
        </thead>
        <tbody>
          ${queues.map((u, i) => `
              <tr>
                <td>${u.name}</td>
                <td>${parseInt(qResData[i].averageMessagesPerTicket)}</td>
              </tr>
            `)
    }
          <!-- Adicionar mais agentes conforme necessário -->
        </tbody>
      </table>

      <h2>Análise Detalhada por Canal de Atendimento</h2>
      ${queues.map((q, i) => {
      const responseTimes = ticketsResponses[i].data.map(item => item.firstResponseTime);
      const totalTime = responseTimes.reduce((sum, time) => sum + time, 0);
      const averageResponseTime = totalTime / responseTimes.length;

      return `
        <h3>${q.name}</h3>
        <p>Total de Atendimentos: <strong>${qResData[i].totalAtendimentos}</strong></p>
        <p>Tempo médio para a primeira resposta: <strong>${averageResponseTime > 60 ? parseInt(averageResponseTime /60) + " horas" : parseInt(averageResponseTime) + " minutos"}</strong></p>
        <p>Tempo médio para a resolução total do problema: <strong>${qResData[i].averageResolutionTime > 60 ? parseInt(qResData[i].averageResolutionTime / 60) + " horas" : parseInt(qResData[i].averageResolutionTime) + " minutos"}</strong></p>
      `
    })
    }
    </div>
  `;

  return content;
};

// Função para gerar o PDF usando html2pdf
export const generatePDF = async (data) => {
  const { user, personName, initDateRef, endDateRef } = data

  try {
    const queues = personName.map(ticket => ticket.queue) // Extrai o objeto 'queue' de cada ticket
      .filter((queue, index, self) =>
        index === self.findIndex(q => q.id === queue.id) // Filtra para manter apenas filas únicas pelo 'id'
      );

    const qRequests = queues.map(u =>
      api.get(`/queues/${u.id}/general-info`, {
        params: {
          initDateRef: initDateRef.current.value,
          endDateRef: endDateRef.current.value
        }
      })
    );
    const qResponses = await Promise.all(qRequests)
    const qResData = await Promise.all(qResponses.map(res => res.data))

    const ticketRequests = personName.reduce((acc, ticket) => {
      const queueName = ticket.queue.name;

      if (!acc[queueName]) {
        acc[queueName] = [];
      }

      acc[queueName].push(
        api.get(`/tickets/${ticket.id}/general-info`, {
          params: {
            initDateRef: initDateRef.current.value,
            endDateRef: endDateRef.current.value
          }
        })
      );

      return acc;
    }, {});

    const ticketsResponses = await Promise.all(
      Object.entries(ticketRequests).map(async ([queue, requests]) => {
        const responses = await Promise.all(requests);
        return {
          queue,
          data: responses.map(res => res.data)
        };
      })
    );
    console.log(ticketsResponses);

    const content = await printContent({ ...data, qResData, ticketsResponses, queues }); // Seu conteúdo HTML

    const options = {
      margin: 1,
      filename: 'Relatório_total_de_mensagens_por_atendimento.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'portrait'
      }
    };

    // Adiciona cabeçalho e rodapé personalizados
    html2pdf().from(content).set(options).toPdf().get('pdf').then(function (pdf) {
      const totalPages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Carregar a imagem da logo (exemplo: base64 ou caminho da imagem)
      const logoImg = 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/1280px-Coca-Cola_logo.svg.png'; // Exemplo de uma imagem base64 da logo

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);

        // Cabeçalho - Logo e Nome da Empresa
        pdf.setFontSize(12);
        pdf.addImage(logoImg, 'PNG', 1, pageWidth - 7.5, 1, 0.5); // Adiciona a logo no canto esquerdo
        pdf.text('EMPRESA TOPZERA BROKK', pageWidth - 2.5, 1); // Nome da empresa ao lado da logo

        // Rodapé - Nome do Emitente e Data
        const currentDate = new Date().toLocaleDateString('pt-BR');
        pdf.setFontSize(10);
        pdf.text(`Emitido por: ${user.name}`, pageWidth - 7.5, pageHeight - 1);
        pdf.text(`Data de emissão: ${currentDate}`, pageWidth - 2.5, pageHeight - 1);
      }
    }).save();
  } catch (error) {
    console.error('Erro ao buscar dados das Áreas:', error);
  }
};

export default printContent;

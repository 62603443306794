import useAuth from "../../hooks/useAuth";
import api from "../../services/api";
import "./report.css"; // Seu CSS para estilização extra
import html2pdf from "html2pdf.js"; // Certifique-se de importar o html2pdf

// Função para gerar o conteúdo HTML
const printContent = async (data) => {
  const content = document.createElement('div');
  const { selectedQueues, selectedUsers, queuesData, usersData, initDateRef, endDateRef } = data

  const initDate = new Date(initDateRef.current.value);
  const endDate = new Date(endDateRef.current.value);
  const diffInMs = endDate - initDate;
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  content.innerHTML = `
    <div class="container">
      <h1>Relatório por Tempo Médio de Espera (TME)</h1>
      <p>Este relatório apresenta a análise do Tempo Médio de Espera (TME) de cada canal e agente de atendimento no período de <strong>${diffInDays} dias</strong>. O objetivo é avaliar a eficiência dos atendimentos e identificar áreas que necessitam de melhorias para reduzir o TME.</p>

      <h2>Desempenho Geral</h2>
      
      <h3>Tempo Médio de Espera por Canal</h3>
      <table>
        <thead>
          <tr>
            <th>CANAL DE ATENDIMENTO</th>
            <th>TME (Minutos)</th>
          </tr>
        </thead>
        <tbody>
        ${selectedQueues.map((q, i)=> `
            <tr>
              <td>${q.name}</td>
              <td>${parseInt(queuesData[i].averageFirstResponseTime)}</td>
            </tr>
          `)
    }
          <!-- Adicionar mais canais conforme necessário -->
        </tbody>
      </table>

      <h3>Tempo Médio de Espera por Agente</h3>
      <table>
        <thead>
          <tr>
            <th>AGENTE</th>
            <th>TME (Minutos)</th>
          </tr>
        </thead>
        <tbody>
          ${selectedUsers.map((u, i) => `
              <tr>
                <td>${u.name}</td>
                <td>${parseInt(usersData[i].averageFirstResponseTime)}</td>
              </tr>
            `)
    }
          <!-- Adicionar mais agentes conforme necessário -->
        </tbody>
      </table>

      <h2>Análise Detalhada por Canal</h2>
      ${selectedQueues.map((q, i) => `
        <h3>${q.name}</h3>
        <p>Total de Atendimentos: <strong>${queuesData[i].totalAtendimentos}</strong></p>
        <p>Tempo Médio de Espera (TME): <strong>${queuesData[i].averageFirstResponseTime > 60 ? parseInt(queuesData[i].averageFirstResponseTime /60) + " horas" : parseInt(queuesData[i].averageFirstResponseTime) + " minutos"}</strong></p>
      `)
    }
    </div>
  `;

  return content;
};

// Função para gerar o PDF usando html2pdf
export const generatePDF = async (data) => {
  const { user, selectedQueues, selectedUsers, initDateRef, endDateRef } = data
  
  try {
    const getQueuesInfosPromise = selectedQueues.map(q =>
      api.get(`/queues/${q.id}/general-info`, {
        params: {
          initDateRef: initDateRef.current.value,
          endDateRef: endDateRef.current.value
        }
      })
    )
    const queuesResponse = await Promise.all(getQueuesInfosPromise)
    const queuesData = await Promise.all(queuesResponse.map(res => res.data))

    const getUsersInfosPromise = selectedUsers.map(u =>
      api.get(`/users/${u.id}/general-info`, {
        params: {
          initDateRef: initDateRef.current.value,
          endDateRef: endDateRef.current.value
        }
      })
    )
    const usersResponse = await Promise.all(getUsersInfosPromise)
    const usersData = await Promise.all(usersResponse.map(res => res.data))

    const content = await printContent({ ...data, queuesData, usersData });

    const options = {
      margin: 1,
      filename: 'Relatório_por_Tempo_Médio_de_Espera.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'portrait'
      }
    };

    // Adiciona cabeçalho e rodapé personalizados
    html2pdf().from(content).set(options).toPdf().get('pdf').then(function (pdf) {
      const totalPages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Carregar a imagem da logo (exemplo: base64 ou caminho da imagem)
      const logoImg = 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/1280px-Coca-Cola_logo.svg.png'; // Exemplo de uma imagem base64 da logo

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);

        // Cabeçalho - Logo e Nome da Empresa
        pdf.setFontSize(12);
        pdf.addImage(logoImg, 'PNG', 1, pageWidth - 7.5, 1, 0.5); // Adiciona a logo no canto esquerdo
        pdf.text('EMPRESA TOPZERA BROKK', pageWidth - 2.5, 1); // Nome da empresa ao lado da logo

        // Rodapé - Nome do Emitente e Data
        const currentDate = new Date().toLocaleDateString('pt-BR');
        pdf.setFontSize(10);
        pdf.text(`Emitido por: ${user.name}`, pageWidth - 7.5, pageHeight - 1);
        pdf.text(`Data de emissão: ${currentDate}`, pageWidth - 2.5, pageHeight - 1);
      }
    }).save();
  } catch (error) {
    console.error('Erro ao buscar dados dos usuários ou áreas:\n', error);
  }
};

export default printContent;

import React, { useRef } from "react";

import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useTheme
} from "@material-ui/core";

import { generatePDF } from "./reportBody";

import useAuth from "../../hooks/useAuth";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: 50,
    display: 'flex',
    flexDirection: "column",
    gap: 50,
    width: "100%",
    minHeight: "50%"
  },
  datesRow: {
    display: 'flex',
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  title: {
    marginTop: 20
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  textField: {
    width: 400,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    alignSelf: "center",
    margin: "3em 0 0"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  printBtn: {
    marginTop: "auto",
    alignSelf: "end"
  }
}));

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ReportByAgents = () => {
  const { user } = useAuth()
  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const initDateRef = useRef('')
  const endDateRef = useRef('')

  const handleSelectUsers = (event) => {
    setPersonName(event.target.value);
  };

  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await api.get("/users")
        setUsers(data.users)
      }
      catch (err) {
        toastError(err)
      }
    }

    fetchUsers();
  }, [])

  return (
    <form className={classes.mainContainer} noValidate>
      <div className={classes.datesRow}>
        <TextField
          id="date"
          label="De"
          type="date"
          defaultValue=""
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={initDateRef}
        />

        <TextField
          id="date"
          label="Até"
          type="date"
          defaultValue=""
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={endDateRef}
        />
      </div>

      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">Agentes:</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={personName}
          onChange={handleSelectUsers}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((user) => (
                <Chip key={user.id} label={user.name} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {users.map((user) => (
            <MenuItem key={user.id} value={user} style={getStyles(user.name, personName, theme)}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        className={classes.printBtn}
        disabled={personName.length > 0 && initDateRef.current.value !== "" && endDateRef.current.value !== "" ? false : true}
        onClick={() => {
          generatePDF({ user, personName, initDateRef, endDateRef });
        }}>
        Exporta relatorio
      </Button>
    </form>
  )
}

export default ReportByAgents;
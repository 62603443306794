import React, { useRef } from "react";

import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useTheme
} from "@material-ui/core";

import PausedCallsAgentsReport from "../../components/PausedCallsAgentsReport";
import ReportByAgents from "../../components/ReportByAgents";
import ReportByAttendance from "../../components/ReportByAttendance";
import ReportByServiceChannel from "../../components/ReportByServiceChannel";
import AverageServiceTimeReport from "../../components/AverageServiceTimeReport";
import TMEReport from "../../components/TMEReport";
import TotalMessageServiceReport from "../../components/TotalMessageServiceReport";

import { jsPDF } from "jspdf";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import printContent from "./Files/canais";


const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 25
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 20
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Reports = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [value, setValue] = React.useState('1');
  const initDateRef = useRef('')
  const endDateRef = useRef('')


  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await api.get("/users")
        setUsers(data.users)
      }
      catch (err) {
        toastError(err)
      }
    }

    fetchUsers();
  }, [])

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSelectUsers = (event) => {
    setPersonName(event.target.value);
  };

  const exportUsersReport = () => {
    console.log(personName)
    console.log(initDateRef.current.value)
    console.log(endDateRef.current.value)
  }

  const getPageBody = () => {
    switch (value) {
      case "1":
        return <ReportByAgents />
      case "2":
        return <PausedCallsAgentsReport />
      case "3":
        return <ReportByAttendance />
      case "4":
        return <ReportByServiceChannel />
      case "5":
        return <AverageServiceTimeReport />
      case "6":
        return <TMEReport />
      case "7":
        return <TotalMessageServiceReport />
    }
  }

  return (
    <MainContainer>
      <MainHeader className={classes.mainContainer}>
        <Title>Relatórios</Title>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">Escolher o tipo de relatório para exporta</FormLabel>
          <RadioGroup row aria-label="relatório" name="reportsType" value={value} onChange={handleChange}>
            <FormControlLabel value="1" control={<Radio />} label="Agentes" />
            <FormControlLabel value="2" control={<Radio />} label="Atendimentos Pausados por Agente" />
            <FormControlLabel value="3" control={<Radio />} label="Atendimentos" />
            <FormControlLabel value="4" control={<Radio />} label="Canal de Atendimento" />
            <FormControlLabel value="5" control={<Radio />} label="Tempo Médio de Atendimento (TMA)" />
            <FormControlLabel value="6" control={<Radio />} label="Tempo Médio de Espera (TME)" />
            <FormControlLabel value="7" control={<Radio />} label="Total de Mensagens por Atendimento" />
          </RadioGroup>
        </FormControl>


        {getPageBody()}
      </Paper>
    </MainContainer>
  );
};

export default Reports;
